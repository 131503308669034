<template>
  <div v-if="schema.tags && schema.tags.length > 0">
    <div>
      <div>
        <label>{{ $t('categories') }}</label>
        <b-form-group>
          <b-form-checkbox-group v-model="field">
            <b-card v-for="item in items" no-body class="mb-1" :key="item.header">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle="'accordion-' + item.header">
                <span>
                  {{ item.header }} <span v-if="categorySelectedCount(item.items) > 0">({{ categorySelectedCount(item.items) }})</span>
                </span>
                </b-button>
              </b-card-header>
              <b-collapse :id="'accordion-' + item.header" :accordion="'accordion-' + item.header">
                <b-card-body>
                  <b-card-text>
                    <b-row>
                      <b-col
                        v-for="subitem in item.items"
                        :key="subitem.header"
                        sm="12"
                        md="4"
                        lg="3"
                      >
                        <b-form-checkbox :disabled="disabled" :value="getValue(subitem)">
                          {{ getLabel(subitem) }}
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <div>
      <label>{{ $t('attributes') }}</label>
      <b-form-group class="row" style="margin: 0px">
        <b-form-checkbox-group v-model="field">
          <b-form-checkbox
            class="col-4"
            :inline="true"
            :disabled="disabled"
            v-for="item in childItems"
            :key="getLabel(item)"
            :value="getValue(item)"
          >
            <img :src="getImageSrc(item)" width="35" />
            <span>{{ getLabel(item) }}</span>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
  import {abstractField} from 'vue-form-generator'
  import {APIService} from "../../../services/api";

  export default {
    mixins: [abstractField],
    data() {
      return {
        field: [],
        items: [],
        childItems: [],
      }
    },
    async beforeMount() {
      const items = await APIService.get(`tag-item?sort=orderKey,ASC&sort=id,DESC&sort=children.orderKey,ASC&join=children&join=parent&filter=parent.id||$isnull&join=tag&filter=tag.token||eq||category`);
      this.items = items.map(tagItem => {
        delete tagItem.productCounts;
        return {
          header: tagItem.value,
          items: tagItem.children.map(child => {
            delete child.productCounts;
            return child;
          })
        }
      });
      this.childItems = await APIService.get(`tag-item?sort=value,ASC&join=tag&filter=tag.token||eq||attribute`);
      if (this.value) {
        this.field = this.value;
      }
    },
    watch: {
      field: function (newValue) {
        this.value = newValue;
      }
    },
    methods: {
      getLabel (item) {
        return item['value'] ? item['value'] : '';
      },
      getValue (originalItem) {
        let item = JSON.parse(JSON.stringify(originalItem))
        delete item.tag;
        delete item.images;
        const existingElement = this.value ? this.value.filter(singleValue => singleValue.relatedTagItem.id === item.id) : [];
        const id = existingElement.length > 0 ? existingElement[0].id : null;
        const orderKey = existingElement.length > 0 ? existingElement[0].orderKey : null;
        return id
          ? {
            id,
            orderKey: orderKey,
            relatedTagItem: item,
          }
          : {
            orderKey: orderKey,
            relatedTagItem: item,
          };
      },
      getImageSrc (item) {
        const image = item.images[0];
        if (image) {
          const modifications = image.modifications;
          const modiification = modifications.find(m => m.suffix === 'normal');
          if (modiification) {
            return modiification.fileUrl;
          }
        }
        return '';
      },
      categorySelectedCount (categorySelected) {
        return categorySelected.filter(category => this.field.some(cat => cat.relatedTagItem.id === category.id)).length;
      },
    }
  }
</script>

<style lang="scss" scoped>
  .btn-secondary, .btn-secondary:focus, .btn-secondary:active {
    background: transparent !important;
    border: none !important;
    text-align: left !important;
    box-shadow: none !important;
  }
</style>

