<template>
  <div>
    <div v-for="(attributeData, index) in field" :key="index">
      <div v-if="attributeData.tagAttribute.type === 1">
        <label>{{ attributeData.tagAttribute.name }}</label>
        <b-form-input @change="updateModel" :disabled="disabled" v-model="attributeData.value" :placeholder="attributeData.tagAttribute.name" required max="255"/>
      </div>
      <div v-if="attributeData.tagAttribute.type === 2">
        <label>{{ attributeData.tagAttribute.name }}</label>
        <b-form-input @change="updateModel" :disabled="disabled" v-model="attributeData.value" :placeholder="attributeData.tagAttribute.name" type="number" required min="0"/>
      </div>
      <div v-if="attributeData.tagAttribute.type === 4">
        <label>{{ attributeData.tagAttribute.name }}</label>
        <b-form-select @change="updateModel" :disabled="disabled" v-model="attributeData.value" :options="getOptions(attributeData.tagAttribute)"></b-form-select>
      </div>
    </div>
  </div>
</template>

<script>
  import { abstractField } from 'vue-form-generator'

  export default {
    mixins: [abstractField],
    data () {
      return {
        field: [],
      }
    },
    async beforeMount() {
      const field = [];
      if (!this.value) {
        for (const attribute of this.schema.attributes) {
          field.push({
            value: null,
            tagAttribute: attribute,
          });
        }
        this.field = field;
      } else {
        this.field = this.value;
      }
    },
    methods: {
      updateModel() {
        this.value = this.field;
      },
      getOptions(attribute) {
        const schemaAttribute = this.schema.attributes.filter(schemaAttribute => schemaAttribute.id === attribute.id)[0];
        return schemaAttribute.enums.map(enumValue => {
          return {
            value: enumValue.code,
            text: enumValue.value,
          }
        });
      }
    }
  }
</script>
