import { validators } from "vue-form-generator";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "input",
      inputType: "text",
      label: "value",
      model: "value",
      required: true,
      translation: true,
      placeholder: "value",
      max: 255,
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("valueRequired"),
          textTooBig: i18n.t("valueMustBeLess{1}")
        }),
      ]
    },
    {
      type: "editor",
      label: "shortDescription",
      model: "shortDescription",
      required: true,
      translation: true,
      placeholder: "shortDescription",
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("shortDescription"),
        }),
      ]
    },
    {
      type: "editor",
      label: "description",
      model: "description",
      required: true,
      translation: true,
      placeholder: "description",
      validator: [
        validators.string.locale({
          fieldIsRequired: i18n.t("description"),
        }),
      ]
    },
  ]
};
